class StringHelper {
  /**
   * Returns a truncated string with a trailing "..."
   *
   * @param {string} input The string to truncate
   * @param {number} stringLength The length to truncate the string to
   * @param {boolean} useLastWordBoundary If set to true the string is truncated to the end of the last word in the resulting substring
   *
   * @returns {string} The truncated input string
   */
  truncate(
    input: string,
    stringLength: number,
    useLastWordBoundary = false
  ): string {
    if (input.length <= stringLength) {
      return input;
    }

    const subString = input.substring(0, stringLength);

    return (
      (useLastWordBoundary
        ? subString.substring(0, subString.lastIndexOf(" "))
        : subString) + "&hellip;"
    );
  }
  /**
   * JSON stringifys the specified item to lower case
   *
   * @param {unknown} item The item to stringify
   *
   * @returns {string} The stringified item
   */
  stringifyToLowerCase(item: unknown): string {
    return JSON.stringify(item).toLowerCase();
  }
  /**
   * Adds a leading zero to the input number if it is les than 10
   *
   * @param {number} input The input number
   *
   * @returns {string} The number as string
   */
  addLeadingZero(input: number): string {
    return input < 10 ? `0${input}` : input.toString();
  }
}

export default new StringHelper();
